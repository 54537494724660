import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getGisData, getSapData } from '../features/overview/overviewSlice';
import OverviewCard from '../components/OverviewCard';
import Navigation from '../components/Navigation';
import KenDiagram from '../components/KenDiagram';

export default function OverviewStats() {
  const dispatch = useDispatch();


  // Data from Redux store
  const { gis, sap } = useSelector((state) => state.overview);

  // Fetch asset data on component mount
  useEffect(() => {
    dispatch(getGisData());
    dispatch(getSapData());
  }, [dispatch]);


  return (
    <>
      <Navigation current="overview" />
      <div className="overview container">
        <div className="row">
          <div className="col-12">
            {/* Overview Cards */}
            {
                gis ?
                <OverviewCard
                  date="03/10/2024"
                  totalErrors={gis.totalErrors}
                  totalRecords={gis.totalRecords}
                  percentageErrors={gis.percentageErrors}
                  title="GIS View"
                  subtitle="(SAP Assets Missing from GIS System)"
                />:""
            }

            {
                sap ?
                <OverviewCard
                  date="29/09/2024"
                  totalErrors={sap.totalErrors}
                  totalRecords={sap.totalRecords}
                  percentageErrors={sap.percentageErrors}
                  title="SAP View"
                  subtitle="(GIS Assets Missing from SAP System)"
                />:""
            }
            {/* Ken Diagram */}
            <KenDiagram />
          </div>
        </div>
      </div>
    </>
  );
}
