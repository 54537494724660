import Papa from 'papaparse';

// Utility Functions
const parseCsv = (file) =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => resolve(result.data),
      error: (error) => reject(error),
    });
  });

const toggleValue = (dataList, value) =>
  dataList.includes(value)
    ? dataList.filter((item) => item !== value)
    : [...dataList, value];

// Fetch and Parse Data
const getData = async () => {
  const url =
    'https://docs.google.com/spreadsheets/d/e/2PACX-1vQatbKvzcxZG_dPhsfTnBEN82rTqTemgvKlpRU9kqkxs2wUeXNGLgmZg8J7SrS8YwIUMhisN05K92E-/pub?output=csv';

  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Failed to fetch data');
    const csvText = await response.text();
    return parseCsv(csvText);
  } catch (error) {
    console.error('Error fetching or parsing data:', error);
    return [];
  }
};

// Filter Functions
const addFilter = ({ key_, value, filterData }) => {
  const updatedFilterData = { ...filterData };
  updatedFilterData[key_] = toggleValue(filterData[key_] || [], value);
  return updatedFilterData;
};

const assetTypeFilter = ({ value, assetData }) => toggleValue(assetData, value);

const syncFilter = ({ value, filterSync }) => toggleValue(filterSync, value);

// Error Filtering
const filterError = (results, assetType, errorType) => {
  const baseFilter = (res) => res.technical_object_type_description === assetType;

  const errorConditions = {
    'No record in SAP': (res) =>
      baseFilter(res) &&
      (!res.gis_present || res.gis_present === 'FALSE') &&
      (!res.gis_obj_type_match || res.gis_obj_type_match === 'FALSE'),
    'Object Type mismatch': (res) =>
      baseFilter(res) &&
      res.gis_present === 'TRUE' &&
      (!res.gis_obj_type_match || res.gis_obj_type_match === 'FALSE'),
  };

  const checkFalse = results.filter(errorConditions[errorType] || (() => false));
  const checkTrue = results.filter(
    (res) =>
      baseFilter(res) &&
      res.gis_present === 'TRUE' &&
      res.gis_obj_type_match === 'TRUE'
  );

  return { checkFalse, checkTrue };
};

// Asset Type Table
const getAssetTypeTable = async (id) => {
  const filterErrorType = localStorage.getItem('filterErrorType');
  const results = await getData();
  const distinctTypes = [
    ...new Set(results.map((item) => item.technical_object_type_description)),
  ];
  const sourceLayer = distinctTypes[id];
  const { checkFalse } = filterError(results, sourceLayer, filterErrorType);
  return checkFalse;
};

// Asset Types
const getAssetType = async (filterErrorType) => {
  const results = await getData();
  localStorage.setItem('filterErrorType', filterErrorType);
  const distinctTypes = [
    ...new Set(results.map((item) => item.technical_object_type_description)),
  ];

  return distinctTypes.map((type, index) => {
    const { checkFalse, checkTrue } = filterError(results, type, filterErrorType);
    return {
      type,
      falsedata: checkFalse,
      false: checkFalse.length,
      true: checkTrue.length,
      objectId: index,
    };
  });
};

// Error List
const getErrorList = async () => {
  const results = await getData();

  const errorCounts = results.reduce(
    (acc, res) => {
      if (
        (!res.gis_present || res.gis_present === 'FALSE') &&
        (!res.gis_obj_type_match || res.gis_obj_type_match === 'FALSE')
      ) {
        acc.no_record++;
      }
      if (
        res.gis_present === 'TRUE' &&
        (!res.gis_obj_type_match || res.gis_obj_type_match === 'FALSE')
      ) {
        acc.mismatch++;
      }
      return acc;
    },
    { no_record: 0, mismatch: 0, asset_name_mismatch: 0, op_status_mismatch: 0 }
  );

  return errorCounts;
};

const errorTypeFilter = errortype =>{
  return errortype
}

// Service Object
const sapService = {
  getData,
  getAssetType,
  getErrorList,
  getAssetTypeTable,
  assetTypeFilter,
  addFilter,
  syncFilter,
  errorTypeFilter
};

export default sapService;
