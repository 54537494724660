import React from 'react';
import helper from '../utils/helper';

const DownloadCSV = ({ data, fileName }) => {
    const handleDownload = () => {
        helper.downloadCSV(data, fileName);
    };

    return (
        <button 
            className="button" 
            style={{ marginBottom: '30px' }} 
            onClick={handleDownload}
        >
            Download CSV
        </button>
    );
};

export default DownloadCSV;
