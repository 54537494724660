import React, { useEffect, useState } from 'react';

export default function OverviewCard({ date, totalErrors, totalRecords, percentageErrors, title, subtitle }) {
    return (
        <div className="card mt-5 mb-5">
            <div className="card-header">
                <h4>{title} <span>{subtitle}</span></h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col board date-of-download">
                        <p style={{fontSize:"27px",fontWeight:"800", color:"#69893B"}}>{date}</p>
                        <label style={{textTransform:"uppercase", position:"relative", top:"-17px"}}> Date of download</label>
                    </div>
                    <div className="col board">
                        <h3 style={{fontSize:"27px",fontWeight:"800", color:"red"}}>{totalErrors}</h3>
                        <label style={{textTransform:"uppercase"}}>Total Error</label>
                    </div>
                    <div className="col board">
                        <h3 style={{fontSize:"27px",fontWeight:"800", color:"black"}}>{totalRecords}</h3>
                        <label style={{textTransform:"uppercase"}}>Total Records</label>
                    </div>
                    <div className="col board">
                        <h3 style={{fontSize:"27px",fontWeight:"500", color:"black"}}>{percentageErrors}%</h3>
                        <label style={{textTransform:"uppercase"}}>Percentage Errors</label>
                    </div>
                    {/* <div className="col board note-board">
                        <p style={{textTransform:"uppercase"}}>Notes</p>
                        <textarea rows="1" placeholder="Type Something..."></textarea>
                    </div> */}
                </div>
            </div>
        </div>
    )
}