import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import sapService from './sapService';

const initialState = {
  filteredData: {
    assetType: [],
    errorType: [],
    syncStatus: [],
  },
  filterDataAsset: [],
  filterErrorType: 'No record in SAP',
  filterSync: [],
  allData: [],
  asset_type: [],
  assetTypeTable: [],
  errorList: { no_record: 0, mismatch: 0, sap_floc_missing_gi: 0 },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
  asset_type_sap: [],
};

// Utility function for handling rejected async actions
const handleRejected = (state, action, defaultData = null) => {
  state.isLoading = false;
  state.isError = true;
  state.message = action.payload;
  if (defaultData !== null) {
    Object.assign(state, defaultData);
  }
};

// Async Thunks
const createAsyncThunkWithService = (name, serviceMethod) =>
  createAsyncThunk(name, async (data, thunkAPI) => {
    try {
      return await serviceMethod(data);
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message || error.toString()
      );
    }
  });

export const addFilter = createAsyncThunkWithService('sap/addFilter', sapService.addFilter);
export const assetTypeFilter = createAsyncThunkWithService('sap/assetTypeFilter', sapService.assetTypeFilter);
export const syncFilter = createAsyncThunkWithService('sap/syncFilter', sapService.syncFilter);
export const errorTypeFilter = createAsyncThunkWithService('sap/errorTypeFilter', sapService.errorTypeFilter);
export const getAssetTypeSap = createAsyncThunkWithService('sap/getAssetType', sapService.getAssetType);
export const getAssetTypeTable = createAsyncThunkWithService('sap/getAssetTypeTable', sapService.getAssetTypeTable);
export const getErrorList = createAsyncThunkWithService('sap/getErrorList', sapService.getErrorList);

// Slice Definition
export const sapSlice = createSlice({
  name: 'sap',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetTypeSap.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetTypeSap.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.asset_type = action.payload;
        state.asset_type_sap = action.payload;
      })
      .addCase(getAssetTypeSap.rejected, (state, action) => {
        handleRejected(state, action, { asset_type: [], asset_type_sap: [] });
      })
      .addCase(getErrorList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getErrorList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.errorList = action.payload;
      })
      .addCase(getErrorList.rejected, (state, action) => {
        handleRejected(state, action, {
          errorList: { no_record: 0, mismatch: 0, sap_floc_missing_gi: 0 },
        });
      })
      .addCase(getAssetTypeTable.fulfilled, (state, action) => {
        state.assetTypeTable = action.payload;
      })
      .addCase(addFilter.fulfilled, (state, action) => {
        state.filteredData = action.payload;
      })
      .addCase(assetTypeFilter.fulfilled, (state, action) => {
        state.filterDataAsset = action.payload;
      })
      .addCase(errorTypeFilter.fulfilled, (state, action) => {
        state.filterErrorType = action.payload;
      })
      .addCase(syncFilter.fulfilled, (state, action) => {
        state.filterSync = action.payload;
      });
  },
});

export const { reset } = sapSlice.actions;
export default sapSlice.reducer;
