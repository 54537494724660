import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getErrorList, reset } from '../features/data/dataSlice';

const ErrorList = () => {
    const dispatch = useDispatch();
    const { errorList} = useSelector((state) => state.data);

    // Fetch the error list on component mount
    useEffect(() => {
        dispatch(getErrorList());

        return () => dispatch(reset());
    }, [dispatch]);

    /**
     * Calculates the total errors from the error list.
     * @returns {number} Total errors.
     */
    const getTotalErrors = () => {
        return (
            (errorList?.sap_floc_missing_gi || 0) +
            (errorList?.no_record || 0) +
            (errorList?.mismatch || 0)
        );
    };

    return (
        <div className="col-2" style={{ marginLeft: '5px' }}>
            {/* Header Section */}
            <div className="row">
                <div className="col board" style={{ padding: '33px 20px', border: 'none' }}>
                    <p style={{ color: 'white' }}>N</p>
                </div>
            </div>

            {/* Table Section */}
            <div className="row table-row mt-4 mb-4">
                <div className="col-12 bg-blue">
                    <h3>Error List</h3>
                </div>
                <table className="table top-border-blue table-bordered">
                    <thead className="bg-blue">
                        <tr>
                            <th className="bg-blue" scope="col"></th>
                            <th className="bg-blue" scope="col">Assets</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">SAP Floc missing in GIS</th>
                            <td>{errorList?.sap_floc_missing_gi || 0}</td>
                        </tr>
                        <tr>
                            <th scope="row">No record in SAP</th>
                            <td>{errorList?.no_record || 0}</td>
                        </tr>
                        <tr>
                            <th scope="row">Object Type mismatch</th>
                            <td>{errorList?.mismatch || 0}</td>
                        </tr>
                        <tr>
                            <th scope="row">Total</th>
                            <td>{getTotalErrors()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ErrorList;
