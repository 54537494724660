import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeTable } from '../features/data/dataSlice';
import DownloadCSV from '../components/DownloadCSV';
import '../static/table.scss';

export default function Asset() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { assetTypeTable, filterErrorType } = useSelector(
        (state) => state.data
    );
    const [keys, setKeys] = useState([]);

    useEffect(() => {
        dispatch(getAssetTypeTable(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (assetTypeTable.length > 0) {
            setKeys(Object.keys(assetTypeTable[0]));
        }
    }, [assetTypeTable]);
    const getAssetTypeName = name =>{
        switch(name){
            case "SL_SWR_PUMP_STN_SYNC":
                return "Sewer - Pump Stations"
            case "SL_SWR_TRMN_PLNT_SYNC":
                return "Sewer - Treatment Plants"
            case "SL_WTNK_BLD_DPTS_WRKS_SYNC":
                return "WS Buildings - Depots and Workshops"
            case "SL_WTNK_BLD_WS_STAFF_HSNG_SYNC":
                return "WS Buildings - Staff Housing"
            case "SL_WTNK_BULK_BRHL_SYNC":
                return "Bulk Water - Boreholes"
            case "SL_WTNK_BULK_DAMS_SYNC":
                return "Bulk Water - Dams"
            case "SL_WTNK_BULK_PUMP_STN_SYNC":
                return "Bulk Water - Pump Stations"
            case "SL_WTNK_BULK_RSRV_SYNC":
                return "Bulk Water - Reservoirs"
            case "SL_WTNK_BULK_TRTM_PLNT_SYNC":
                return "Bulk Water - Treatment Plants"
            case "SL_WTNK_SPR_RSRV_SYNC":
                return "Spring Water - Reservoir"
            case "SL_WTNK_TE_PUMP_STN_SYNC":
                return "Treated Effluent - Pump Stations"
            case "SL_WTNK_TE_RSRV_SYNC":
                return "Treated Effluent - Reservoirs"
            case "SL_WTR_PUMP_STN_SYNC":
                return "Water - Pump Stations"
            case "SL_WTR_ZONE_MNG_STN_SYNC":
                return "Water - Zone Management Stations"
            case "SL_WTR_RSRV_SYNC":
                return "Water - Reservoirs"
        }
    }
    if(assetTypeTable.length < 1){

    }
    return (
        <div className="container">
            <div className="row">
                {
                    assetTypeTable.length < 1 ? <div className="col-12 d-flex justify-content-center align-items-center " style={{height:"100vh", flexDirection:"column"}}>
                    <button onClick={()=>{window.history.back()}} className='btn mt-5' style={{textDecoration:"underline"}}>Go Back</button>
                    <br/>
                    <p style={{textAlign:"center", fontSize:"30px", fontWeight:"500"}}>Dataset is empty</p>
                    </div>:
                    <div className="col-12">
                    <button onClick={()=>{window.history.back()}} className='btn mt-5' style={{textDecoration:"underline"}}>Go Back</button> 
                    {assetTypeTable.length > 0 ? <h4 style={{margin:'30px 0'}}>Asset Type:  {getAssetTypeName(assetTypeTable[0]['source_layer'])}</h4>:''}
                        {assetTypeTable.length > 0 ? <DownloadCSV data={assetTypeTable} fileName={assetTypeTable[0]['source_layer']} />:''}
                        
                        <a href=""></a>
                        {/* Add this div wrapper to handle the scrolling */}
                        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <table className="table top-border-blue asset-table table-bordered">
                                <thead className="bg-blue">
                                    <tr className="bg-blue">
                                        {keys.map((item, i) => {
                                            return (
                                                <th className="bg-blue" scope="col" key={i}>{item}</th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        assetTypeTable.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    {keys.map((itemk, index) => {
                                                        return (
                                                            <td key={index}>{item[itemk]}</td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
