import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dataService from './dataService';

const initialState = {
  filteredData: {
    assetType: [],
    errorType: [],
    syncStatus: [],
  },
  filterDataAsset: [],
  filterErrorType: 'No record in SAP',
  filterSync: "",
  allData: [],
  asset_type: [],
  assetTypeTable: [],
  errorList: { no_record: 0, mismatch: 0, sap_floc_missing_gi: 0 },
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
};

// Utility to handle rejected async actions
const handleRejected = (state, action) => {
  state.isLoading = false;
  state.isError = true;
  state.message = action.payload;
};

// Async Thunks
export const addFilter = createAsyncThunk('data/addFilter', async (data, thunkAPI) => {
  try {
    return await dataService.addFilter(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

export const assetTypeFilter = createAsyncThunk('data/assetTypeFilter', async (data, thunkAPI) => {
  try {
    return await dataService.assetTypeFilter(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

export const syncFilter = createAsyncThunk('data/syncFilter', async (data, thunkAPI) => {
  try {
    return await dataService.syncFilter(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

export const errorTypeFilter = createAsyncThunk('data/errorTypeFilter', async (data, thunkAPI) => {
  try {
    return await dataService.errorTypeFilter(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

export const getAssetType = createAsyncThunk('data/getAssetType', async (filterErrorType, thunkAPI) => {
  try {
    return await dataService.getAssetType(filterErrorType);
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

export const getAssetTypeTable = createAsyncThunk('data/getAssetTypeTable', async (asset, thunkAPI) => {
  try {
    return await dataService.getAssetTypeTable(asset);
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

export const getErrorList = createAsyncThunk('data/getErrorList', async (_, thunkAPI) => {
  try {
    return await dataService.getErrorList();
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response?.data?.message || error.message || error.toString()
    );
  }
});

// Slice Definition
export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAssetType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.asset_type = action.payload;
      })
      .addCase(getAssetType.rejected, handleRejected)
      .addCase(getErrorList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getErrorList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.errorList = action.payload;
      })
      .addCase(getErrorList.rejected, (state, action) => {
        handleRejected(state, action);
        state.errorList = { no_record: 0, mismatch: 0, sap_floc_missing_gi: 0 };
      })
      .addCase(getAssetTypeTable.fulfilled, (state, action) => {
        state.assetTypeTable = action.payload;
      })
      .addCase(addFilter.fulfilled, (state, action) => {
        state.filteredData = action.payload;
      })
      .addCase(assetTypeFilter.fulfilled, (state, action) => {
        state.filterDataAsset = action.payload;
      })
      .addCase(errorTypeFilter.fulfilled, (state, action) => {
        state.filterErrorType = action.payload;
      })
      .addCase(syncFilter.fulfilled, (state, action) => {
        state.filterSync = action.payload;
      });
  },
});

export const { reset } = dataSlice.actions;
export default dataSlice.reducer;
