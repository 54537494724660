import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

// Redux actions
import { getAssetType, reset } from '../features/data/dataSlice';

// Components
import BarChart from './BarChart';

// Utilities
import helper from '../utils/helper';

/**
 * Utility function to map asset type codes to human-readable names.
 */
const getAssetTypeName = (name) => {
    const assetTypeMapping = {
        SL_SWR_PUMP_STN_SYNC: "Sewer - Pump Stations",
        SL_SWR_TRMN_PLNT_SYNC: "Sewer - Treatment Plants",
        SL_WTNK_BLD_DPTS_WRKS_SYNC: "WS Buildings - Depots and Workshops",
        SL_WTNK_BLD_WS_STAFF_HSNG_SYNC: "WS Buildings - Staff Housing",
        SL_WTNK_BULK_BRHL_SYNC: "Bulk Water - Boreholes",
        SL_WTNK_BULK_DAMS_SYNC: "Bulk Water - Dams",
        SL_WTNK_BULK_PUMP_STN_SYNC: "Bulk Water - Pump Stations",
        SL_WTNK_BULK_RSRV_SYNC: "Bulk Water - Reservoirs",
        SL_WTNK_BULK_TRTM_PLNT_SYNC: "Bulk Water - Treatment Plants",
        SL_WTNK_SPR_RSRV_SYNC: "Spring Water - Reservoir",
        SL_WTNK_TE_PUMP_STN_SYNC: "Treated Effluent - Pump Stations",
        SL_WTNK_TE_RSRV_SYNC: "Treated Effluent - Reservoirs",
        SL_WTR_PUMP_STN_SYNC: "Water - Pump Stations",
        SL_WTR_ZONE_MNG_STN_SYNC: "Water - Zone Management Stations",
        SL_WTR_RSRV_SYNC: "Water - Reservoirs",
    };
    return assetTypeMapping[name] || name;
};

/**
 * Table Component
 * Displays asset type data with errors and records breakdown.
 */
const Table = () => {
    const [totalErrors, setTotalErrors] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [percentageErrors, setPercentageErrors] = useState(0);

    const dispatch = useDispatch();
    const { asset_type, filterSync, filterErrorType, filterDataAsset } = useSelector((state) => state.data);

    useEffect(() => {
        dispatch(getAssetType(filterErrorType));

        return () => dispatch(reset());
    }, [dispatch, filterErrorType, filterDataAsset]);

    useEffect(() => {
        let errors = 0;
        let records = 0;

        asset_type.forEach((item) => {
            if (filterDataAsset.length > 0 || filterSync.length > 0) {
                if (filterDataAsset.includes(item.type) || filterSync.includes(item.lu_sync)) {
                    errors += item.false;
                    records += item.true + item.false;
                }
            } else {
                errors += item.false;
                records += item.true + item.false;
            }
        });

        setTotalErrors(errors);
        setTotalRecords(records);
        setPercentageErrors(((errors / Math.max(records, 1)) * 100).toFixed(1));
    }, [asset_type, filterDataAsset, filterSync]);

    /**
     * Renders rows for the asset type table.
     */
    const renderTableRows = () => {
        return asset_type.map((item, index) => {
            const shouldRender = filterDataAsset
                ?(filterDataAsset.length === 0 || filterDataAsset.includes(item.type))
                : true;

            if (shouldRender) {
                return (
                    <tr key={index}>
                        <th scope="row" className="d-flex" style={{ position: "relative" }}>
                            <a href={`/gis_asset/${item.objectId}`} style={{ textAlign: "left" }}>
                                {getAssetTypeName(item.type)}
                            </a>
                            {
                                item.falsedata.length ? 
                                <span
                                    onClick={() => helper.downloadCSV(item.falsedata, getAssetTypeName(item.type))}
                                    style={{ position: "absolute", right: "10px", cursor: "pointer" }}
                                >
                                    <FontAwesomeIcon icon={faDownload} />
                                </span>:""
                            }
                        </th>
                        <td style={{ color: "red", fontWeight: "500" }}>{item.false}</td>
                        <td>{item.true}</td>
                        <td>{item.false + item.true}</td>
                        <td>{((item.false / Math.max(item.false + item.true, 1)) * 100).toFixed(1)}</td>
                    </tr>
                );
            }
            return null;
        });
    };

    return (
        <div className="col-6">
            <div className="row">
                <div className="col board">
                    <h3>{totalErrors}</h3>
                    <label>Total Errors</label>
                </div>
                <div className="col board">
                    <h3>{totalRecords}</h3>
                    <label>Total Records</label>
                </div>
                <div className="col board">
                    <h3>{percentageErrors}%</h3>
                    <label>Percentage Errors</label>
                </div>
            </div>

            <div className="row table-row mt-4 mb-4">
                <div className="col-12 bg-blue">
                    <h3>Records - By Asset Type</h3>
                </div>
                <table className="table top-border-blue table-bordered">
                    <thead className="bg-blue">
                        <tr>
                            <th scope="col">Sources</th>
                            <th scope="col">False</th>
                            <th scope="col">True</th>
                            <th scope="col">Total</th>
                            <th scope="col">% of Errors</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableRows()}
                        <tr>
                            <th scope="row" className="d-flex justify-content-end" style={{ fontWeight: "600" }}>
                                Total
                            </th>
                            <td style={{ color: "red", fontWeight: "500" }}>{totalErrors}</td>
                            <td>{totalRecords - totalErrors}</td>
                            <td>{totalRecords}</td>
                            <td>{((totalErrors / Math.max(totalRecords, 1)) * 100).toFixed(1)}</td>
                        </tr>
                    </tbody>
                </table>

                <div className="col-12 mt-4 mb-4">
                    <h3>Breakdown of Records: True or False</h3>
                    <BarChart
                        asset_type={asset_type}
                        filterErrorType={filterErrorType}
                        filterSync={filterSync}
                        filterDataAsset={filterDataAsset}
                        getAssetTypeName={getAssetTypeName}
                    />
                </div>
            </div>
        </div>
    );
};

export default Table;
