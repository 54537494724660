import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import overviewService from './overviewService';

// Initial State
const initialState = {
  gis: {
    totalRecords: 0,
    totalErrors: 0,
    percentageErrors: 0,
  },
  sap: {
    totalRecords: 0,
    totalErrors: 0,
    percentageErrors: 0,
  },
};

// Async Thunks
const createDataThunk = (type, serviceMethod) =>
  createAsyncThunk(`data/${type}`, async (_, thunkAPI) => {
    try {
      return await serviceMethod();
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response?.data?.message || error.message || error.toString()
      );
    }
  });

export const getGisData = createDataThunk('getGisData', overviewService.getGisData);
export const getSapData = createDataThunk('getSapData', overviewService.getSapData);

// Slice Definition
const overviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGisData.fulfilled, (state, action) => {
        state.gis = action.payload;
      })
      .addCase(getSapData.fulfilled, (state, action) => {
        state.sap = action.payload;
      });
  },
});

// Exports
export const { reset } = overviewSlice.actions;
export default overviewSlice.reducer;
