import { configureStore } from '@reduxjs/toolkit'
import dataReducer from '../features/data/dataSlice'
import sapReducer from '../features/sap/sapSlice'
import overviewReducer from '../features/overview/overviewSlice'

export const store = configureStore({
  reducer: {
    data: dataReducer,
    sap: sapReducer,
    overview:overviewReducer,
  },
})