import helper from '../../utils/helper';
  
const fetchAndParseCsv = helper.fetchAndParseCsv;

  // GIS and SAP Data Fetchers
  const getGis = () =>
    fetchAndParseCsv(
      "https://docs.google.com/spreadsheets/d/1Vhhm-jfNRa2nTMfWFk4N6PQpVpO-Dz1p5lHZrPmPpaw/pub?gid=0&single=true&output=csv"
    );
  
  const getSap = () =>
    fetchAndParseCsv(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vQatbKvzcxZG_dPhsfTnBEN82rTqTemgvKlpRU9kqkxs2wUeXNGLgmZg8J7SrS8YwIUMhisN05K92E-/pub?output=csv"
    );
  
  // Common Error Filtering Logic
  const calculateErrorMetrics = (results, conditions, checkTrueCondition) => {
    const checkTrue = results.filter(checkTrueCondition).length;
  
    const totalErrors = Object.values(conditions)
      .map((condition) => results.filter(condition).length)
      .reduce((sum, count) => sum + count, 0);
  
    const totalRecords = totalErrors + checkTrue;
    const percentageErrors = ((totalErrors / totalRecords) * 100).toFixed(1);
  
    return { totalRecords, totalErrors, percentageErrors };
  };
  
  // GIS Error Filtering
  const filterErrorGis = (results) => {
    const conditions = {
      "No record in SAP": (res) =>
        res.sap_id_in_sap === "FALSE" &&
        res.sap_obj_type_match === "FALSE" &&
        res.sap_id_present === "TRUE",
  
      "Object Type mismatch": (res) =>
        res.sap_id_present === "TRUE" &&
        res.sap_id_in_sap === "TRUE" &&
        res.sap_obj_type_match === "FALSE",
  
      "SAP Floc missing in GIS": (res) =>
        res.sap_id_present === "FALSE" &&
        res.sap_id_in_sap === "FALSE" &&
        res.sap_obj_type_match === "FALSE",
    };
  
    const checkTrueCondition = (res) =>
      res.sap_id_in_sap === "TRUE" &&
      res.sap_obj_type_match === "TRUE" &&
      res.sap_id_present === "TRUE";
  
    return calculateErrorMetrics(results, conditions, checkTrueCondition);
  };
  
  // SAP Error Filtering
  const filterErrorSap = (results) => {
    const conditions = {
      "No record in SAP": (res) =>
        (!res.gis_present || res.gis_present === "FALSE") &&
        (!res.gis_obj_type_match || res.gis_obj_type_match === "FALSE"),
  
      "Object Type mismatch": (res) =>
        res.gis_present === "TRUE" &&
        (!res.gis_obj_type_match || res.gis_obj_type_match === "FALSE"),
    };
  
    const checkTrueCondition = (res) =>
      res.gis_present === "TRUE" && res.gis_obj_type_match === "TRUE";
  
    return calculateErrorMetrics(results, conditions, checkTrueCondition);
  };
  
  // GIS and SAP Data Handlers
  const getGisData = async () => {
    const results = await getGis();
    return filterErrorGis(results);
  };
  
  const getSapData = async () => {
    const results = await getSap();
    return filterErrorSap(results);
  };
  
  // Data Service Object
  const overviewService = {
    getSapData,
    getGisData,
  };
  
  export default overviewService;
  