import Papa from 'papaparse';
const parseCsv = (file) =>
    new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => resolve(result.data),
        error: (error) => reject(error),
      });
    });
    
const helper = {
  /**
   * Converts an array of objects to a CSV string.
   * @param {Object[] | string} objArray - Array of objects or JSON string to convert.
   * @returns {string} CSV formatted string.
   */
  convertToCSV(objArray) {
      const array = Array.isArray(objArray) ? objArray : JSON.parse(objArray);
      return array
          .map((row) =>
              Object.values(row)
                  .map((value) => `"${value}"`) // Enclose each value in quotes for CSV compatibility
                  .join(',')
          )
          .join('\r\n');
  },

  /**
   * Downloads data as a CSV file.
   * @param {Object[] | string} data - Array of objects or JSON string to convert and download.
   * @param {string} fileName - Name of the downloaded file (without extension).
   */
  downloadCSV(data, fileName) {
      const csvString = this.convertToCSV(data);
      const blob = new Blob([csvString], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  },
  async fetchAndParseCsv(url){
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const csvText = await response.text();
      return parseCsv(csvText);
    } catch (error) {
      console.error("Error fetching or parsing data:", error);
      return [];
    }
  },
};

export default helper;
