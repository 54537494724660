import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { getAssetTypeSap } from '../features/sap/sapSlice';
import BarChart from './BarChart';
import helper from '../utils/helper';

const SapTable = () => {
  const dispatch = useDispatch();

  // State variables to manage totals and error percentages
  const [totalErrors, setTotalErrors] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [percentageErrors, setPercentageErrors] = useState(0);

  // Extracting relevant data from Redux store
  const { asset_type, filterSync, filterErrorType, filterDataAsset } = useSelector(
    (state) => state.sap
  );

  // Fetch asset type data when filters change
  useEffect(() => {
    dispatch(getAssetTypeSap(filterErrorType));
  }, [dispatch, filterDataAsset, filterErrorType]);

  // Calculate total errors, records, and error percentage whenever `asset_type` or filters change
  useEffect(() => {
    let errors = 0;
    let records = 0;

    asset_type.forEach((item) => {
      if (filterDataAsset.length === 0 || filterDataAsset.includes(item.type)) {
        errors += item.false;
        records += item.false + item.true;
      }
    });

    setTotalErrors(errors);
    setTotalRecords(records);
    setPercentageErrors(records > 0 ? ((errors / records) * 100).toFixed(1) : 0);
  }, [asset_type, filterDataAsset]);

  // Helper function to map asset type keys to readable names
  const getAssetTypeName = (name) => {
    const mapping = {
      SL_SWR_PUMP_STN_SYNC: 'Sewer - Pump Stations',
      SL_SWR_TRMN_PLNT_SYNC: 'Sewer - Treatment Plants',
      SL_WTNK_BLD_DPTS_WRKS_SYNC: 'WS Buildings - Depots and Workshops',
      SL_WTNK_BLD_WS_STAFF_HSNG_SYNC: 'WS Buildings - Staff Housing',
      SL_WTNK_BULK_BRHL_SYNC: 'Bulk Water - Boreholes',
      SL_WTNK_BULK_DAMS_SYNC: 'Bulk Water - Dams',
      SL_WTNK_BULK_PUMP_STN_SYNC: 'Bulk Water - Pump Stations',
      SL_WTNK_BULK_RSRV_SYNC: 'Bulk Water - Reservoirs',
      SL_WTNK_BULK_TRTM_PLNT_SYNC: 'Bulk Water - Treatment Plants',
      SL_WTNK_SPR_RSRV_SYNC: 'Spring Water - Reservoir',
      SL_WTNK_TE_PUMP_STN_SYNC: 'Treated Effluent - Pump Stations',
      SL_WTNK_TE_RSRV_SYNC: 'Treated Effluent - Reservoirs',
      SL_WTR_PUMP_STN_SYNC: 'Water - Pump Stations',
      SL_WTR_ZONE_MNG_STN_SYNC: 'Water - Zone Management Stations',
      SL_WTR_RSRV_SYNC: 'Water - Reservoirs',
    };
    return mapping[name] || name;
  };

  // Render a table row for each asset type
  const renderTableRow = (item, index) => (
    <tr key={index}>
      <th scope="row" className="d-flex" style={{ position: 'relative' }}>
        <a style={{ textAlign: 'left' }} href={`/sap_asset/${item.objectId}`}>
          {item.type}
        </a>
        {item.falsedata.length ? (
          <span
            onClick={() => helper.downloadCSV(item.falsedata, item.type)}
            style={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </span>
        ) : null}
      </th>
      <td style={{ color: 'red', fontWeight: '500' }}>{item.false}</td>
      <td>{item.true}</td>
      <td>{item.false + item.true}</td>
      <td>{((item.false / (item.false + item.true || 1)) * 100).toFixed(1)}</td>
    </tr>
  );

  return (
    <div className="col-6">
      {/* Display summary statistics */}
      <div className="row">
        <div className="col board">
          <h3>{totalErrors}</h3>
          <label>Total Errors</label>
        </div>
        <div className="col board">
          <h3>{totalRecords}</h3>
          <label>Total Records</label>
        </div>
        <div className="col board">
          <h3>{percentageErrors}%</h3>
          <label>Percentage Errors</label>
        </div>
      </div>

      {/* Render records table */}
      <div className="row table-row mt-4 mb-4">
        <div className="col-12 bg-blue">
          <h3>Records - By Asset Type</h3>
        </div>
        <table className="table top-border-blue table-bordered">
          <thead className="bg-blue">
            <tr>
              <th scope="col">Sources</th>
              <th scope="col">False</th>
              <th scope="col">True</th>
              <th scope="col">Total</th>
              <th scope="col">% of Errors</th>
            </tr>
          </thead>
          <tbody>
            {asset_type.map((item, index) => {
              if (filterDataAsset.length === 0 || filterDataAsset.includes(item.type)) {
                return renderTableRow(item, index);
              }
              return null;
            })}
            {/* Summary row */}
            <tr>
              <th scope="row" className="d-flex justify-content-end" style={{ fontWeight: '600' }}>
                Total
              </th>
              <td style={{ color: 'red', fontWeight: '500' }}>{totalErrors}</td>
              <td>{totalRecords - totalErrors}</td>
              <td>{totalRecords}</td>
              <td>{totalRecords > 0 ? ((totalErrors / totalRecords) * 100).toFixed(1) : 0}</td>
            </tr>
          </tbody>
        </table>

        {/* Bar chart visualization */}
        <div className="col-12 mt-4 mb-4">
          <h3>Breakdown of Records: True or False</h3>
          <BarChart
            isSAp={true}
            filterErrorType={[]}
            asset_type={asset_type}
            filterSync={filterSync}
            filterDataAsset={filterDataAsset}
            getAssetTypeName={getAssetTypeName}
          />
        </div>
      </div>
    </div>
  );
};

export default SapTable;
