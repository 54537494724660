import React, { useEffect, useState, useRef } from "react";
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

function BarChart({asset_type, filterDataAsset,filterSync,filterErrorType, getAssetTypeName, isSAp}){

    const [labels, setLabels] = useState([])
    const [trueData, setTruedata] = useState([])
    const [falseData, setFalsedata] = useState([])


    useEffect(() => {
        getLabel()
    },[asset_type, filterDataAsset])

    const getLabel = ()=>{
        var tempLabels = []
        var tempTrue = []
        var tempFalse = []
        for(let i =0; i<asset_type.length; i++){
            if(filterDataAsset.length > 0){
                var item = asset_type[i]
                if(filterDataAsset.includes(item.type) || filterErrorType.includes(item.summary_concat) || filterSync.includes(item.lu_sync) ){
                    if(isSAp){
                        tempLabels.push(asset_type[i].type)
                    }
                    else{
                        tempLabels.push(getAssetTypeName(asset_type[i].type))
                    }
                    tempTrue.push(asset_type[i].true)
                    tempFalse.push(asset_type[i].false)
                }
            }
            else{
                if(isSAp){
                    tempLabels.push(asset_type[i].type)
                }
                else{
                    tempLabels.push(getAssetTypeName(asset_type[i].type))
                }
                tempTrue.push(asset_type[i].true)
                tempFalse.push(asset_type[i].false)
            }
        }
        setLabels(tempLabels)
        setTruedata(tempTrue)
        setFalsedata(tempFalse)
    }
    if(asset_type.length < 1){
        return(<></>)
    }
    return(
        <Bar
        redraw={true}
        datasetIdKey='bar'
        data={{
          labels: labels,
          datasets: [
            {
              id: 'true',
              label: 'True',
              data: trueData,
            },
            {
                id: 'false',
                label: 'False',
                data: falseData,
              },
          ],
        }}
        options={{
            plugins: {
                legend: {
                    display: true,
                }
            }
        }
        }
        />
    )
}

export default BarChart