import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { errorTypeFilter, assetTypeFilter } from '../features/sap/sapSlice';

const SapFilter = () => {
  const dispatch = useDispatch();
  const { filterErrorType, filterDataAsset, asset_type } = useSelector((state) => state.sap);

  const toggleActiveClass = (id) => {
    const element = document.getElementById(id);
    element?.classList.toggle('active');
  };

  const handleAssetClick = (asset, id) => {
    dispatch(assetTypeFilter({ value: asset.type, assetData: filterDataAsset }));
    toggleActiveClass(id);
  };

  const handleErrorTypeClick = (errorType) => {
    dispatch(errorTypeFilter(errorType));
  };

  const renderAssetItems = () =>
    asset_type.map((asset, index) => (
      <div
        className="item"
        key={index}
        id={`asset-${index}`}
        onClick={() => handleAssetClick(asset, `asset-${index}`)}
      >
        {asset.type}
      </div>
    ));

  const errorTypeOptions = [
    { id: 'no-record', label: 'No corresponding GIS record found', value: 'No record in SAP' },
    { id: 'mismatch', label: 'Object Type mismatch', value: 'Object Type mismatch' },
    { id: 'asset-name', label: 'Asset name mismatch', value: 'Asset name mismatch' },
    { id: 'missing', label: 'Operational status mismatch', value: 'Operational status mismatch' },
  ];

  const renderErrorTypeItems = () =>
    filterErrorType &&
    errorTypeOptions.map(({ id, label, value }) => (
      <div
        key={id}
        id={id}
        className={`item ${filterErrorType === value ? 'active' : ''}`}
        onClick={() => handleErrorTypeClick(value)}
      >
        {label}
      </div>
    ));

  return (
    <div className="col-md-3 mt-2 filter">
      <div className="date-of-download">
        <p>03 / 05 / 2024</p>
        <label>Date of download</label>
      </div>
      <div className="filter-by-asset">
        <div className="label mb-2">Filter - By Asset Type</div>
        {renderAssetItems()}
        <div className="label mt-3 mb-2">Filter - By Error Type</div>
        {renderErrorTypeItems()}
      </div>
    </div>
  );
};

export default SapFilter;
