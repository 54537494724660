import React from 'react';
import Navigation from '../components/Navigation';
import SapFilter from '../components/SapFilter';
import SapTable from '../components/SapTable';
import SapErrorList from '../components/SapErrorList';

export default function SAP() {
    return(
        <div>
            <Navigation current={"sap"} />
            <div className="container">
                <div className="row">
                    <SapFilter />
                    <SapTable />
                    <SapErrorList />
                </div>
            </div>
        </div>
    )
}