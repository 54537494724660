import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getErrorList, reset } from '../features/sap/sapSlice';

const SapErrorList = () => {
    const dispatch = useDispatch();
    const [total, setTotal] = useState(0);

    const { errorList } = useSelector((state) => state.sap);

    // Fetch error list on component mount
    useEffect(() => {
        dispatch(getErrorList());
        return () => dispatch(reset());
    }, [dispatch]);

    // Calculate total errors when errorList updates
    useEffect(() => {
        if (errorList) {
            const totalErrors =
                (errorList.no_record || 0) +
                (errorList.op_status_mistmatch || 0) +
                (errorList.mismatch || 0) +
                (errorList.asset_name_mistmatch || 0);
            setTotal(totalErrors);
        }
    }, [errorList]);

    return (
        <div className="col-2" style={{ marginLeft: '5px' }}>
            {/* Header Section */}
            <div className="row">
                <div className="col board" style={{ padding: '33px 20px', border: 'none' }}>
                    <p style={{ color: 'white' }}>N</p>
                </div>
            </div>

            {/* Error List Table */}
            <div className="row table-row mt-4 mb-4">
                <div className="col-12 bg-blue">
                    <h3>Error List</h3>
                </div>
                <table className="table top-border-blue table-bordered">
                    <thead className="bg-blue">
                        <tr>
                            <th scope="col"></th>
                            <th scope="col">Assets</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[
                            { label: 'No record in SAP', value: errorList?.no_record || 0 },
                            { label: 'Object Type mismatch', value: errorList?.mismatch || 0 },
                            { label: 'Asset name mismatch', value: errorList?.asset_name_mistmatch || 0 },
                            { label: 'Operational status mismatch', value: errorList?.op_status_mistmatch || 0 },
                        ].map(({ label, value }, index) => (
                            <tr key={index}>
                                <th scope="row">{label}</th>
                                <td>{value}</td>
                            </tr>
                        ))}
                        <tr>
                            <th scope="row">Total</th>
                            <td>{total}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SapErrorList;
