import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { errorTypeFilter, assetTypeFilter,syncFilter } from '../features/data/dataSlice'

function Filter(){
    const dispatch = useDispatch()
    const [assets, setAssets] = useState([
        'SL_SWR_PUMP_STN_SYNC','SL_SWR_TRMN_PLNT_SYNC','SL_WTR_PUMP_STN_SYNC','vSL_WTR_RSRV_SYNC','SL_WTR_ZONE_MNG_STN_SYNC','SL_WTNK_BLD_DPTS_WRKS_SYNC',
        'SL_WTNK_BLD_WS_STAFF_HSNG_SYNC','SL_WTNK_BULK_BRHL_SYNC','SL_WTNK_BULK_DAMS_SYNC','SL_WTNK_BULK_PUMP_STN_SYNC','SL_WTNK_BULK_RSRV_SYNC',
        'SL_WTNK_BULK_TRTM_PLNT_SYNC','SL_WTNK_SPR_RSRV_SYNC','SL_WTNK_TE_PUMP_STN_SYNC','SL_WTNK_TE_RSRV_SYNC'
    ])
    const error_type = ['No record in SAP', 'Object Type mismatch', 'SAP Floc missing in GIS']
    const { filterSync, filterErrorType, filterDataAsset, isSuccess, message } = useSelector(
        (state) => state.data
    )

    const handleAsset = e =>{
        var element = document.getElementById(e.target.id);
        const isActive = Array.from(element.classList).some(c => c.startsWith('active'));
        if (isActive){
            element.classList.remove('active')
        }
        else{
            element.classList.add('active')
        }
    }

    const getAssetTypeName = name =>{
        switch(name){
            case "SL_SWR_PUMP_STN_SYNC":
                return "Sewer - Pump Stations"
            case "SL_SWR_TRMN_PLNT_SYNC":
                return "Sewer - Treatment Plants"
            case "SL_WTNK_BLD_DPTS_WRKS_SYNC":
                return "WS Buildings - Depots and Workshops"
            case "SL_WTNK_BLD_WS_STAFF_HSNG_SYNC":
                return "WS Buildings - Staff Housing"
            case "SL_WTNK_BULK_BRHL_SYNC":
                return "Bulk Water - Boreholes"
            case "SL_WTNK_BULK_DAMS_SYNC":
                return "Bulk Water - Dams"
            case "SL_WTNK_BULK_PUMP_STN_SYNC":
                return "Bulk Water - Pump Stations"
            case "SL_WTNK_BULK_RSRV_SYNC":
                return "Bulk Water - Reservoirs"
            case "SL_WTNK_BULK_TRTM_PLNT_SYNC":
                return "Bulk Water - Treatment Plants"
            case "SL_WTNK_SPR_RSRV_SYNC":
                return "Spring Water - Reservoir"
            case "SL_WTNK_TE_PUMP_STN_SYNC":
                return "Treated Effluent - Pump Stations"
            case "SL_WTNK_TE_RSRV_SYNC":
                return "Treated Effluent - Reservoirs"
            case "SL_WTR_PUMP_STN_SYNC":
                return "Water - Pump Stations"
            case "SL_WTR_ZONE_MNG_STN_SYNC":
                return "Water - Zone Management Stations"
            case "SL_WTR_RSRV_SYNC":
                return "Water - Reservoirs"
        }
    }
    
    return(
        <div className="col-md-3 mt-2 filter">
            <div className="date-of-download">
                <p>03 / 05 / 2024</p>
                <label> Date of download</label>
            </div>
            <div className="filter-by-asset">
                <div className='label mb-2'>
                Filter - By Asset Type
                </div>
                {
                    assets.map((asset, index)=>{
                        return(
                            <div className="item" onClick={(e)=>{
                                dispatch(assetTypeFilter({ value:asset, assetData:filterDataAsset}))
                                handleAsset(e)
                            }} name={asset} id={'asset-' + index} key={index}>{getAssetTypeName(asset)}</div>
                        )
                    })
                }


                <div className='label mt-3 mb-2'>
                Filter - By Error Type
                </div>
                {
                    filterErrorType ?<>
                    
                    <div id='no-record' onClick={(e)=>{
                        dispatch(errorTypeFilter('No record in SAP'))
                        //handleAsset(e)
                    }} className={filterErrorType === 'No record in SAP'? "item mt-3 active" :"item "}>No record in SAP</div>
                    <div id='mismatch' onClick={(e)=>{
                        dispatch(errorTypeFilter('Object Type mismatch'))
                        //handleAsset(e)
                    }} className={filterErrorType === 'Object Type mismatch'? "item active" :"item "}>Object Type mismatch</div>
                <div id="missing" onClick={(e)=>{
                                dispatch(errorTypeFilter('SAP Floc missing in GIS'))
                            }} className={filterErrorType === 'SAP Floc missing in GIS'? "item active" :"item "}>SAP Floc missing in GIS</div>
                    </>:""
                }


                
                {/* <div className='label mt-3 mb-2'>
                Filter - By Sync Status
                </div>
                <div id='sap-synced' onClick={(e)=>{
                                dispatch( filterSync === 'SAP Synced'? syncFilter(''): syncFilter('SAP Synced') )
                            }} className={filterSync === 'SAP Synced'? "item mt-3 active" :"item "} >SAP Synced</div>
                <div id='yes' onClick={(e)=>{
                                dispatch(filterSync === 'Yes'? syncFilter('') : syncFilter('Yes'))
                            }} className={filterSync === 'Yes'? "item active" :"item "} >Yes</div> */}


            </div>
        </div>
    )
}
export default Filter;